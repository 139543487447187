<script>
import VxDialogView from "@/components/vx/VxDialogView";
import VxWalkthru from "./VxWalkthru";

export default {
  components: {
    VxDialogView,
    VxWalkthru,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<template>
  <VxDialogView :fullscreen="true">
    <template #title>
      <slot name="title">
        {{ title }}
      </slot>
    </template>

    <VxWalkthru>
      <slot></slot>
    </VxWalkthru>
  </VxDialogView>
</template>
