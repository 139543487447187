<script>
export default {
  name: "VxWalkthruTitle",
};
</script>

<template>
  <div class="vx-walkthru-title">
    <slot></slot>
  </div>
</template>
