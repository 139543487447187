<script>
import {
  VxWalkthruView,
  VxWalkthruTitle,
  VxWalkthruDescription,
} from "@/components/vx/VxWalkthru";

import SoftwareUpdateSteps from "./SoftwareUpdateSteps";

export default {
  name: "SoftwareUpdateView",

  components: {
    VxWalkthruView,
    VxWalkthruTitle,
    VxWalkthruDescription,
    SoftwareUpdateSteps,
  },

  computed: {
    currentVersion() {
      return "";
    },
  },
};
</script>

<template>
  <VxWalkthruView>
    <template #title>
      <VxWalkthruTitle>
        {{ $t("settings.softwareUpdate.title") }}
      </VxWalkthruTitle>
    </template>
    <VxWalkthruDescription>
      {{ $t("settings.softwareUpdate.description") }}
    </VxWalkthruDescription>
    <SoftwareUpdateSteps />
  </VxWalkthruView>
</template>
