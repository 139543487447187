<script>
export default {
  name: "VxWalkthruStep",
  props: {
    step: {
      type: Number,
      required: true,
    },
    complete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<template>
  <div class="vx-walkthru-step">
    <v-stepper-step :step="step" :complete="complete" editable>
      <slot></slot>
    </v-stepper-step>
    <v-stepper-content :step="step">
      <slot name="detail"></slot>
    </v-stepper-content>
  </div>
</template>
