<script>
import { VxWalkthruSteps } from "@/components/vx/VxWalkthru";

import { SOFTWARE_UPDATES_QUERY } from "./SoftwareUpdateGql.js";

export default {
  name: "SoftwareUpdateView",

  components: {
    VxWalkthruSteps,
    UpgradeStep: () => import("./UpgradeStep"),
    CurrentReleaseStep: () => import("./CurrentReleaseStep"),
    DowngradeStep: () => import("./DowngradeStep"),
  },

  apollo: {
    releaseNotes: {
      query: SOFTWARE_UPDATES_QUERY,
      variables() {
        return {
          // TODO: make this real
          product: "POS",
          clientVersion: this.curVer,
        };
      },
      skip() {
        return !this.curVer;
      },
    },
  },

  data: (vm) => ({
    curStep: 1,
    releaseNotes: {},
    curVer: undefined,
  }),
  computed: {
    loading() {
      return this.$apolloData.loading > 0;
    },
    current() {
      return this.releaseNotes.current;
    },
    upgrades() {
      return this.releaseNotes.upgrades;
    },
    downgrades() {
      return this.releaseNotes.downgrades;
    },
  },

  created() {
    const vm = this;
    this.getClientVersion().then((r) => {
      console.log("setting version to", r);
      vm.curVer = r;
    });
  },

  methods: {
    async getClientVersion() {
      if (process.env.NODE_ENV === "development") return "2.20.1.1094";
      if (!this.$raiPos) return null;

      const result = await this.$raiPos.version();
      return result;
    },
  },
};
</script>

<template>
  <div>
    <VxWalkthruSteps :step.sync="curStep" :steps="3" :loading="loading">
      <UpgradeStep :step="1" :releases="upgrades" />
      <CurrentReleaseStep :step="2" :release="current" />
      <DowngradeStep :step="3" :releases="downgrades" />
    </VxWalkthruSteps>
  </div>
</template>
