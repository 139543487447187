<script>
export default {
  name: "VxWalkthruDescription",
};
</script>

<template>
  <div class="vx-walkthru-description">
    <slot></slot>
  </div>
</template>
