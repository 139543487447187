<script>
import { VStepper, VSkeletonLoader } from "vuetify/lib";

export default {
  components: {
    VStepper,
    VSkeletonLoader,
  },
  props: {
    step: {
      type: Number,
      required: true,
      default: 0,
    },
    // only used to know how many steps a skeleton should show
    steps: {
      type: Number,
      required: false,
      default: 0,
    },
    // when true, shows ${steps} skeletons
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    skeleton() {
      return this.loading && this.steps > 0 && false;
    },
    computedStep: {
      get() {
        return this.step;
      },
      set(v) {
        this.$emit("update:step", v);
      },
    },
    computedComponent() {
      return this.skeleton ? "v-skeleton-loader" : "v-stepper";
    },
    computedProps() {
      return {
        // only splats if skeleton
        ...(this.skeleton && {
          loading: true,
          type: `card-heading@${this.steps}`,
        }),

        // only splats if not skeleton
        ...(!this.skeleton && {
          ...this.$attrs,
          steps: this.steps,
          vertical: true,
          nonLinear: true,
        }),
      };
    },
  },
};
</script>

<template>
  <component
    :is="computedComponent"
    v-model="computedStep"
    v-bind.sync="computedProps"
    v-on="$listeners"
  >
    <template v-if="!loading">
      <slot></slot>
    </template>
  </component>
</template>
